import React from 'react';
import { FaDownload } from 'react-icons/fa';

const Downloads = () => {
  const apps = [
    {
      title: "AfyaSafe Doctor App",
      shortDesc: "Empower doctors to manage patient care remotely with ease.",
      description: [
        "Manage patient appointments efficiently",
        "Conduct video consultations",
        "Access patient medical history",
        "Issue digital prescriptions",
        "Track patient progress",
        "Manage your availability calendar"
      ],
      image: "./doctor-screenshot.png",
      link: "https://play.google.com/store/apps/details?id=com.afya_safe_options.doctor"
    },
    {
      title: "AfyaSafe Customer App",
      shortDesc: "Connect with doctors, track health, and order medications.",
      description: [
        "Book appointments with healthcare providers",
        "Video consultations from home",
        "Order medicines online",
        "Store medical records securely",
        "Set medication reminders",
        "Track your health vitals"
      ],
      image: "./customer-screenshot.png",
      link: "https://play.google.com/store/apps/details?id=com.afya_safe_options.customer"
    },
    {
      title: "AfyaSafe Vendor App",
      shortDesc: "For hospitals and pharmacies to manage treatments and medicines.",
      description: [
        "Manage medicine inventory",
        "Process prescription orders",
        "Track delivery status",
        "Manage patient records",
        "Generate sales reports",
        "Handle multiple store locations"
      ],
      image: "./vendor-screenshot.png",
      link: "https://play.google.com/store/apps/details?id=com.afya_safe_options.vendor"
    },
    {
      title: "AfyaSafe Partner App",
      shortDesc: "Enable delivery partners to deliver medications safely and timely.",
      description: [
        "Accept delivery requests",
        "Optimize delivery routes",
        "Real-time order tracking",
        "Secure delivery confirmation",
        "Manage earnings",
        "Handle multiple deliveries"
      ],
      image: "./partner-screenshot.png",
      link: "https://play.google.com/store/apps/details?id=com.afya_safe_options.partner"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-extrabold text-blue-800 text-center mb-6">
          Download the apps
        </h1>
        <p className="text-xl text-gray-700 text-center max-w-2xl mx-auto mb-12">
          Choose the app that best suits your needs and start your health journey with AfyaSafe today.
        </p>

        <div className="space-y-6">
          {apps.map((app, index) => (
            <div 
              key={index} 
              className={`flex flex-col md:flex-row bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 ${
                index % 2 === 1 ? 'md:flex-row-reverse' : ''
              }`}
            >
              {/* App Screenshot */}
              <div className="w-full md:w-2/5 flex items-center justify-center p-4 bg-gray-50">
                <div className="relative w-48 h-96 md:w-64 md:h-[400px]">
                  <img
                    src={app.image}
                    alt={app.title}
                    className="w-full h-full object-contain rounded-lg"
                  />
                </div>
              </div>

              {/* App Details */}
              <div className="w-full md:w-3/5 p-6 md:p-8 flex flex-col justify-center">
                <h3 className="text-2xl font-semibold text-blue-800 mb-2">
                  {app.title}
                </h3>
                <p className="text-gray-600 mb-4 text-lg">
                  {app.shortDesc}
                </p>
                
                {/* Features List */}
                <div className="mb-6">
                  <h4 className="text-sm font-semibold text-gray-500 mb-2">KEY FEATURES</h4>
                  <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                    {app.description.map((feature, idx) => (
                      <li key={idx} className="flex items-center text-gray-600">
                        <svg className="w-4 h-4 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>

                <a
                  href={app.link}
                  className="inline-flex items-center justify-center space-x-2 px-4 py-2 bg-blue-500 text-white font-medium rounded-full hover:bg-blue-600 transition duration-300 w-auto self-start text-sm"
                >
                  <FaDownload className="text-sm"/>
                  <span>Download Now</span>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Downloads;
