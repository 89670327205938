import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaLinkedin, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import Home from './components/Home';
import About from './components/About';
import HowItWorks from './components/HowItWorks';
import ContactUs from './components/ContactUs';
import Downloads from './components/Downloads';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';

function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <Router>
      {/* Header */}
      <header className="bg-blue-700 p-4 shadow-md sticky top-0 z-50">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <div className="bg-white p-2 rounded">
              <img src="./white_logo.png" alt="AfyaSafe Options Logo" className="h-8 sm:h-10" />
            </div>
            <h1 className="text-white text-xl sm:text-3xl font-bold">
              <Link to="/" className="hover:text-gray-200">AfyaSafe Options</Link>
            </h1>
          </div>
          
          {/* Mobile menu button */}
          <button 
            className="md:hidden text-white"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMobileMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
            </svg>
          </button>
          
          {/* Desktop Navigation */}
          <nav className="hidden md:block">
            <ul className="flex space-x-6">
              <li><Link className="text-white hover:text-gray-200 transition duration-200" to="/">Home</Link></li>
              <li><Link className="text-white hover:text-gray-200 transition duration-200" to="/about">About</Link></li>
              <li><Link className="text-white hover:text-gray-200 transition duration-200" to="/how-it-works">How It Works</Link></li>
              <li><Link className="text-white hover:text-gray-200 transition duration-200" to="/downloads">Downloads</Link></li>
              <li><Link className="text-white hover:text-gray-200 transition duration-200" to="/contact-us">Contact Us</Link></li>
            </ul>
          </nav>
          
          {/* Mobile Navigation */}
          <nav className={`md:hidden absolute top-full left-0 right-0 bg-blue-700 ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
            <ul className="flex flex-col items-center space-y-4 py-4">
              <li><Link onClick={handleLinkClick} className="text-white hover:text-gray-200 transition duration-200" to="/">Home</Link></li>
              <li><Link onClick={handleLinkClick} className="text-white hover:text-gray-200 transition duration-200" to="/about">About</Link></li>
              <li><Link onClick={handleLinkClick} className="text-white hover:text-gray-200 transition duration-200" to="/how-it-works">How It Works</Link></li>
              <li><Link onClick={handleLinkClick} className="text-white hover:text-gray-200 transition duration-200" to="/downloads">Downloads</Link></li>
              <li><Link onClick={handleLinkClick} className="text-white hover:text-gray-200 transition duration-200" to="/contact-us">Contact Us</Link></li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main className="min-h-screen bg-gray-100">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/downloads" element={<Downloads />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path = "/Terms" element={<TermsAndConditions /> }/>
        </Routes>
      </main>

      {/* Footer */}
      <footer className="bg-gray-800 text-white p-6">
        <div className="container mx-auto">
          {/* Main Footer Content */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
            {/* Company Info */}
            <div>
              <h3 className="text-lg font-semibold mb-4">AfyaSafe Options</h3>
              <p className="text-gray-400 mb-4">Providing accessible health options for all.</p>
              <div className="flex space-x-6">
                <a href="https://web.facebook.com/afyasafeoptions" className="text-gray-400 hover:text-blue-500 transition duration-200">
                  <FaFacebook size={24} />
                </a>
                <a href="https://x.com/afyasafeoptions" className="text-gray-400 hover:text-blue-400 transition duration-200">
                  <FaTwitter size={24} />
                </a>
                <a href="https://www.linkedin.com/company/afyachapchap" className="text-gray-400 hover:text-blue-300 transition duration-200">
                  <FaLinkedin size={24} />
                </a>
              </div>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li><Link to="/" className="text-gray-400 hover:text-white transition duration-200">Home</Link></li>
                <li><Link to="/about" className="text-gray-400 hover:text-white transition duration-200">About</Link></li>
                <li><Link to="/how-it-works" className="text-gray-400 hover:text-white transition duration-200">How It Works</Link></li>
                <li><Link to="/downloads" className="text-gray-400 hover:text-white transition duration-200">Downloads</Link></li>
                <li><Link to="/contact-us" className="text-gray-400 hover:text-white transition duration-200">Contact Us</Link></li>
              </ul>
            </div>

            {/* Legal Links */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Legal</h3>
              <ul className="space-y-2">
                <li><Link to="/privacy-policy" className="text-gray-400 hover:text-white transition duration-200">Privacy Policy</Link></li>
                <li><Link to="/Terms " className="text-gray-400 hover:text-white transition duration-200">Terms & Conditions</Link></li>
              </ul>
            </div>

            {/* Contact Info */}
            <div>
              <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
              <ul className="space-y-2">
                <li className="flex items-center text-gray-400">
                  <FaEnvelope className="mr-2" /> info@afyasafe.com
                </li>
                <li className="flex items-center text-gray-400">
                  <FaPhone className="mr-2" /> +254 101 400 406
                </li>
                <li className="flex items-center text-gray-400">
                  <FaMapMarkerAlt className="mr-2" /> Nairobi, Kenya
                </li>
              </ul>
            </div>
          </div>

          {/* Divider */}
          <div className="border-t border-gray-700 pt-8">
            {/* Bottom Footer */}
            <div className="flex flex-col md:flex-row justify-between items-center">
              <p className="text-gray-400 text-sm mb-4 md:mb-0">
                &copy; {new Date().getFullYear()} AfyaSafe Options. All rights reserved.
              </p>
              <div className="flex space-x-4 text-sm">
                <Link to="/privacy-policy" className="text-gray-400 hover:text-white transition duration-200">Privacy</Link>
                <span className="text-gray-600">|</span>
                <Link to="/terms" className="text-gray-400 hover:text-white transition duration-200">Terms</Link>
                <span className="text-gray-600">|</span>
                <Link to="/contact-us" className="text-gray-400 hover:text-white transition duration-200">Support</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Router>
  );
}

export default App;
