import React, { useState } from 'react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full py-4 px-6 text-left flex justify-between items-center hover:bg-gray-50"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium text-gray-900">{question}</span>
        <span className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`}>
          ▼
        </span>
      </button>
      <div className={`px-6 py-4 bg-gray-50 ${isOpen ? 'block' : 'hidden'}`}>
        <p className="text-gray-600">{answer}</p>
      </div>
    </div>
  );
};

const HowItWorks = () => {
  const faqs = [
    {
      category: "Appointments",
      items: [
        {
          question: "What is patient appointment system?",
          answer: "Appointment system is the pre-timing of a patient visit to the hospital for consultation with a doctor. It can also be defined as the assignment of date and time for a patient to conveniently see a doctor."
        },
        {
          question: "What do I do if I cannot be available during the booked appointment?",
          answer: "We understand that sometimes treatments/procedures can go beyond schedule. In case you are not able to attend the appointment, you have the option to recommend another time to the patient and if accepted by the patient, the appointment will be rescheduled else the full payment will be refunded back to the customer."
        }
      ]
    },
    {
      category: "Online Consultation",
      items: [
        {
          question: "What is online doctor consultation?",
          answer: "Online doctor consultation or online medical consultation is a method to connect patients and doctors virtually. It is a convenient and easy way to get online doctor advice using doctor apps or telemedicine apps or platforms, and the internet."
        },
        {
          question: "Can I talk to an online doctor? Can I chat with a doctor online?",
          answer: "Yes, you can chat with a doctor online. Our network of board-certified clinicians are available via chat. They can diagnose, prescribe medication for treatment, issue prescription refills, and more."
        }
      ]
    },
    {
      category: "Medicine Orders",
      items: [
        {
          question: "How do you write a medical order?",
          answer: "Patient's name and another identifier, usually date of birth. Medication and strength, amount to be taken, route by which it is to be taken, and frequency. Amount to be given at the pharmacy and number of refills. Signature and physician identifiers like NPI or DEA numbers"
        },
        {
          question: "How does online medicine delivery work?",
          answer: "An online pharmacy sounds convenient; no waiting in queues, no rushing to the pharmacy before it shuts shop for the day, placing the order is easy, moreover, the order can be placed any time of the day and the medicines are conveniently delivered via courier at your doorstep."
        }
      ]
    },
    {
      category: "Lab Tests",
      items: [
        {
          question: "What is the purpose of laboratory test?",
          answer: "A medical procedure that involves testing a sample of blood, urine, or other substance from the body. Laboratory tests can help determine a diagnosis, plan treatment, check to see if treatment is working, or monitor the disease over time."
        },
        {
          question: "Are online lab tests reliable?",
          answer: "An online lab test with the home collection has all the safety, accuracy and quality of a regular test but offers greater convenience and much less stress."
        }
      ]
    }
  ];

  return (
    <div className="flex flex-col">
      {/* Original How It Works Section */}
      <div className="min-h-screen bg-cover bg-center p-10 text-center" 
           style={{ backgroundImage: 'url("./hospital.jpg")' }}>
        {/* Title Section */}
        <h1 className="text-4xl sm:text-5xl font-extrabold text-blue-800 mb-6">
          How It Works
        </h1>
        <p className="mt-4 text-lg sm:text-xl text-white mb-10 max-w-3xl mx-auto">
          Our integrated health app suite connects healthcare providers, patients, pharmacies, and delivery partners to create a seamless healthcare experience.
        </p>

        {/* How it Works Cards Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
          {/* AfyaSafe Doctor */}
          <div className="p-6 border border-gray-300 rounded-lg shadow-lg hover:shadow-2xl bg-white transition-shadow duration-300">
            <div className="flex justify-center items-center mb-4">
              <img src="./doctor.jpg" alt="Doctor" className="rounded-full w-16 h-16 object-cover" />
            </div>
            <h3 className="text-2xl font-semibold text-blue-800 mb-2">AfyaSafe Doctor</h3>
            <p className="text-gray-600">
              Empower doctors to manage patient care remotely and effectively. With AfyaSafe Doctor, healthcare providers can track patient progress and deliver personalized care.
            </p>
          </div>

          {/* AfyaSafe Customer */}
          <div className="p-6 border border-gray-300 rounded-lg shadow-lg hover:shadow-2xl bg-white transition-shadow duration-300">
            <div className="flex justify-center items-center mb-4">
              <img src="./patient.jpg" alt="Customer" className="rounded-full w-16 h-16 object-cover" />
            </div>
            <h3 className="text-2xl font-semibold text-blue-800 mb-2">AfyaSafe Customer</h3>
            <p className="text-gray-600">
              A patient's tool for connecting with doctors, tracking health, and ordering medications. Stay informed and in control of your healthcare needs.
            </p>
          </div>

          {/* AfyaSafe Vendor */}
          <div className="p-6 border border-gray-300 rounded-lg shadow-lg hover:shadow-2xl bg-white transition-shadow duration-300">
            <div className="flex justify-center items-center mb-4">
              <img src="/hospital.jpg" alt="Vendor" className="rounded-full w-16 h-16 object-cover" />
            </div>
            <h3 className="text-2xl font-semibold text-blue-800 mb-2">AfyaSafe Vendor</h3>
            <p className="text-gray-600">
              For hospitals and pharmacies to manage treatment plans and dispense medicines seamlessly. AfyaSafe Vendor ensures smooth inventory and patient treatment management.
            </p>
          </div>

          {/* AfyaSafe Partner */}
          <div className="p-6 border border-gray-300 rounded-lg shadow-lg hover:shadow-2xl bg-white transition-shadow duration-300">
            <div className="flex justify-center items-center mb-4">
              <img src="./vendor.jpg" alt="Partner" className="rounded-full w-16 h-16 object-cover" />
            </div>
            <h3 className="text-2xl font-semibold text-blue-800 mb-2">AfyaSafe Partner</h3>
            <p className="text-gray-600">
              Enables delivery partners to ensure timely and secure delivery of medications. Our partners play a crucial role in getting healthcare to those who need it most.
            </p>
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-12">
          <a href="/contact-us"
             className="inline-block px-12 py-4 bg-blue-600 text-white font-semibold text-lg rounded-full shadow-lg hover:bg-blue-700 hover:scale-105 transition-transform duration-300">
            Get in Touch
          </a>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="bg-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-extrabold text-blue-800 text-center mb-12">
            Frequently Asked Questions (FAQs)
          </h2>
          
          <div className="mt-8 space-y-12">
            {faqs.map((category, index) => (
              <div key={index} className="mb-8">
                <h3 className="text-2xl font-semibold text-blue-700 mb-4">
                  {category.category}
                </h3>
                <div className="border rounded-lg shadow-sm">
                  {category.items.map((faq, faqIndex) => (
                    <FAQItem
                      key={faqIndex}
                      question={faq.question}
                      answer={faq.answer}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
