import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center p-10 text-center" style={{ backgroundImage: 'url("./hospdp.jpg")' }}>

      {/* Hero Section */}
      <div className="bg-white bg-opacity-80 rounded-lg p-10 shadow-lg max-w-4xl mt-12 text-center">
        <h1 className="text-5xl sm:text-6xl font-extrabold text-blue-800 mb-4 leading-tight">Welcome to AfyaSafe Options</h1>
        <p className="mt-2 text-xl sm:text-2xl text-gray-800 font-medium">
          Empowering Health Through Technology
        </p>
        <p className="mt-6 text-md sm:text-lg text-gray-600 leading-relaxed">
          At AfyaSafe, we believe that everyone deserves access to quality healthcare. We bridge the gap between providers and patients with solutions designed to make healthcare accessible, efficient, and personalized.
        </p>
      </div>

      {/* Benefits Section */}
      <section className="mt-16 max-w-6xl text-center">
        <h2 className="text-4xl font-semibold text-blue-700 mb-6">Why Choose AfyaSafe?</h2>
        <p className="text-lg text-gray-900 mb-10">
          Our platform is crafted for every healthcare participant, with features tailored to specific needs.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="p-6 border rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-200 bg-white bg-opacity-90">
            <img src="./img3.jpg" alt="Doctors" className="mx-auto mb-4 rounded-full" />
            <h3 className="text-xl font-bold text-blue-800">For Doctors</h3>
            <p className="mt-3 text-gray-600">Manage patient schedules, review histories, and focus on what matters most—delivering exceptional care.</p>
          </div>
          <div className="p-6 border rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-200 bg-white bg-opacity-90">
            <img src="./patient.jpg" alt="Patients" className="mx-auto mb-4 rounded-full" />
            <h3 className="text-xl font-bold text-blue-800">For Patients</h3>
            <p className="mt-3 text-gray-600">Book appointments, communicate with providers, and keep your health information secure and accessible.</p>
          </div>
          <div className="p-6 border rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-200 bg-white bg-opacity-90">
            <img src="img5.jpg" alt="Vendors" className="mx-auto mb-4 rounded-full" />
            <h3 className="text-xl font-bold text-blue-800">For Vendors</h3>
            <p className="mt-3 text-gray-600">Manage medical supplies, track orders, and ensure medication distribution is safe and reliable.</p>
          </div>
          <div className="p-6 border rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-200 bg-white bg-opacity-90">
            <img src="./img6.jpg" alt="Partners" className="mx-auto mb-4 rounded-full" />
            <h3 className="text-xl font-bold text-blue-800">For Partners</h3>
            <p className="mt-3 text-gray-600">Deliver prescriptions securely and on time, enhancing patient satisfaction and well-being.</p>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="mt-16 max-w-5xl text-center">
        <h2 className="text-4xl font-semibold text-blue-700 mb-6">Our Mission</h2>
        <p className="text-lg text-gray-600 leading-relaxed">
          AfyaSafe is committed to making healthcare more connected, accessible, and affordable. We leverage technology to deliver tools that empower both providers and patients, creating a healthier, more informed world.
        </p>
      </section>

      {/* CTA Section */}
      <Link
        to="/downloads"
        className="mt-14 inline-block px-12 py-4 bg-blue-600 text-white font-semibold text-lg rounded-full shadow-lg hover:bg-blue-700 hover:scale-105 transition-transform duration-300">
        Download AfyaSafe Apps
      </Link>
    </div>
  );
};

export default Home;
