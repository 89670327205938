import React from 'react';

const About = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center p-10 text-center" style={{ backgroundImage: 'url("blurred.jpg")' }}>

      {/* About Section */}
      <div className="bg-white bg-opacity-80 rounded-lg p-10 shadow-lg max-w-5xl">
        <h1 className="text-4xl sm:text-5xl font-extrabold text-blue-800 mb-6">About AfyaSafe Options</h1>
        <p className="mt-4 text-lg sm:text-xl text-gray-700 leading-relaxed">
          AfyaSafe is committed to making quality healthcare accessible to all. Our range of mobile apps offers tailored
          solutions for patients, doctors, hospitals, and delivery partners, ensuring seamless and efficient health services.
        </p>
        <p className="mt-6 text-lg sm:text-xl text-gray-600 leading-relaxed">
          We believe in the power of technology to transform healthcare, and we're dedicated to supporting the health and wellness of our community.
        </p>
      </div>

      {/* Mission Section */}
      <section className="mt-16 max-w-6xl text-center">
        <h2 className="text-3xl sm:text-4xl font-semibold text-blue-700 mb-6">Our Mission</h2>
        <p className="text-lg sm:text-xl text-gray-600 leading-relaxed mb-10">
          We strive to create a healthier world by providing access to cutting-edge healthcare tools and services that improve patient care, support medical professionals, and enhance community health outcomes.
        </p>
        
        {/* Modified Why We Do It section with mobile-first layout */}
        <div className="flex flex-col md:flex-row gap-8">
          {/* Image container - full width on mobile, half width on desktop */}
          <div className="w-full md:w-1/2 order-1 md:order-1">
            <img
              src="./img6.jpg"
              alt="Healthcare professionals"
              className="rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 w-full"
            />
          </div>
          
          {/* Text container - full width on mobile, half width on desktop */}
          <div className="w-full md:w-1/2 order-2 md:order-2 text-left">
            <h3 className="text-2xl sm:text-3xl font-bold text-blue-800 mb-4">Why We Do It</h3>
            <p className="text-gray-600 leading-relaxed">
              At AfyaSafe, we understand the critical role healthcare plays in society. Our platform connects patients with doctors, hospitals, and medical suppliers, providing a reliable and comprehensive service ecosystem that ensures timely and efficient care.
            </p>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <div className="mt-14 text-center">
        <a
          href="/contact-us"
          className="inline-block px-12 py-4 bg-blue-600 text-white font-semibold text-lg rounded-full shadow-lg hover:bg-blue-700 hover:scale-105 transition-transform duration-300">
          Get in Touch
        </a>
      </div>
    </div>
  );
};

export default About;
