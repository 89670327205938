import React, { useState } from "react";

const PrivacyPolicy = () => {
  const [activeTab, setActiveTab] = useState("doctor");

  const policies = {
    doctor: {
      title: "AfyaSafe Doctor App Privacy Policy",
      lastUpdated: "March 15, 2024",
      content: [
        {
          text: "Protecting your privacy is important to us, and we take the responsibility of handling your personal information very seriously. In this privacy policy, we explain how we collect, use, and disclose your personal information when you use our app",
        },
        {
          section: "1. Information We Collect",
          text: "When you use Afya Safe Options doctor app, we may collect:\n\n• Name, email address, phone number, and contact information\n• Medical qualifications and practice location\n• Information about patient appointments and prescriptions\n• Payment information (bank account, mobile money number, debit/credit card details)\n• Device information (IP address, device type, operating system)",
        },
        {
          section: "2. Use of Information",
          text: "We use your personal information for:\n\n• Providing access to Afya Safe Option doctor app and its features\n• Facilitating communication between you and patients\n• Processing payments for services\n• Improving our app and features\n• Complying with legal requirements\n• Analytics and performance improvement",
        },
        {
          section: "3. Disclosure of Information",
          text: "We may share your information with:\n\n• Patients (for appointment facilitation and communication)\n• Service providers (payment processors and analytics providers)\n• Law enforcement or government authorities (when legally required)\n\nNon-personal information may be shared with third parties for analytics purposes.",
        },
        {
          section: "4. Data Security",
          text: "We take reasonable steps to protect your personal information from:\n\n• Unauthorized access\n• Unauthorized disclosure\n• Destruction\n\nYour data is retained for as long as necessary to provide our services.",
        },
        {
          section: "5. Privacy Policy Updates",
          text: "We may update this privacy policy periodically. You will be notified of any material changes through:\n\n• Email notifications\n• App notifications\n\nContinued use of the app after changes indicates acceptance of the updated policy.",
        },
        {
          section: "6. Contact Information",
          text: "For any questions or concerns about our privacy policy or how we handle your information, please contact us at privacy@afyasafe.com",
        },
      ],
    },
    customer: {
      title: "AfyaSafe Customer App Privacy Policy",
      lastUpdated: "March 15, 2024",
      content: [
        {
          text: "Afya Safe Options is an online medical scheduling platform that provides users with the ability to book appointments with healthcare providers. We are committed to protecting the privacy and security of our users' personal information and have a comprehensive privacy policy in place to govern the collection, use, and sharing of this information.",
        },
        {
          section: "1. Collection of Information",
          text: "Afya Safe Options collects personal information from users including: \n\n• Name, email address, and phone number\n• Date of birth\n• Health information\n• Medical conditions\n• Current medications\n• Insurance information",
        },
        {
          section: "2. Use of Information",
          text: "We use your information to:\n\n• Enable appointment booking with healthcare providers\n• Facilitate communication with providers\n• Help you manage your healthcare\n• Conduct research (using anonymized data only)\n• Improve our services and user experience",
        },
        {
          section: "3. Sharing of Information",
          text: "Afya Safe Options may share personal information with:\n\n• Healthcare providers for appointment management\n• Third-party service providers who help operate the platform\n\nImportantly, we do not sell personal information to third parties for marketing purposes.",
        },
        {
          section: "4. Security Measures",
          text: "We take multiple steps to protect your personal information, including:\n\n• Using encryption technologies\n• Implementing robust security measures\n• Regular security audits\n• Secure data storage practices",
        },
        {
          section: "5. User Controls and Choices",
          text: "Users have the ability to:\n\n• Review their personal information\n• Edit their profile and preferences\n• Control information sharing with providers\n• Request account deletion\n• Export their data",
        },
        {
          section: "6. Account Deletion",
          text: "Users have the right to request the deletion of their account and all associated personal information. Upon request, we will remove your data from our active databases, subject to any legal requirements to maintain certain records.",
        },
        {
          section: "7. Contact Information",
          text: "If you have any questions about this privacy policy or our practices, please contact us at privacy@afyasafe.com or through our support channels in the app.",
        },
      ],
    },
    vendor: {
      title: "AfyaSafe Vendor App Privacy Policy",
      lastUpdated: "March 15, 2024",
      content: [
        {
          text: "Protecting your privacy is important to us, and we take the responsibility of handling your personal information very seriously. In this privacy policy, we explain how we collect, use, and disclose your personal information when you use our app.",
        },
        {
          section: "1. Information We Collect",
          text: "When you use Afya Safe Options vendor app, we may collect:\n\n• Name, email address, phone number, and contact information\n• Qualifications and store location details\n• Information about appointments with patients\n• Products sold to customers\n• Payment information (bank account, mobile money number, debit/credit card details)\n• Device information (IP address, device type, operating system)",
        },
        {
          section: "2. Use of Information",
          text: "We use your personal information for:\n\n• Providing access to Afya Safe Option vendor app and its features\n• Facilitating communication between you and patients\n• Processing payments for services\n• Improving our app and features\n• Complying with legal requirements\n• Analytics and performance improvement",
        },
        {
          section: "3. Disclosure of Information",
          text: "We may share your information with:\n\n• Patients (for appointment facilitation and communication)\n• Service providers (payment processors and analytics providers)\n• Law enforcement or government authorities (when legally required)\n\nNon-personal information may be shared with third parties for analytics purposes.",
        },
        {
          section: "4. Data Security",
          text: "We take reasonable steps to protect your personal information from:\n\n• Unauthorized access\n• Unauthorized disclosure\n• Destruction\n\nYour data is retained for as long as necessary to provide our services.",
        },
        {
          section: "5. Privacy Policy Updates",
          text: "We may update this privacy policy periodically. You will be notified of any material changes through:\n\n• Email notifications\n• App notifications\n\nContinued use of the app after changes indicates acceptance of the updated policy.",
        },
        {
          section: "6. Contact Information",
          text: "For any questions or concerns about our privacy policy or how we handle your information, please contact us at privacy@afyasafe.com",
        },
      ],
    },
    partner: {
      title: "AfyaSafe Partner App Privacy Policy",
      lastUpdated: "March 15, 2024",
      content: [
        {
          text: "Protecting your privacy is important to us, and we take the responsibility of handling your personal information very seriously. In this privacy policy, we explain how we collect, use, and disclose your personal information when you use our app.",
        },
        {
          section: "1. Information We Collect",
          text: "When you sign up as a delivery partner, we collect:\n\n• Name, email address, and phone number\n• Vehicle information (make, model, license plate number)\n• Insurance information\n• Location data\n• Transaction history",
        },
        {
          section: "2. Use of Information",
          text: "We use your information to:\n\n• Provide you with delivery jobs\n• Communicate about your account and transactions\n• Improve our app and services\n• Comply with legal obligations\n• Ensure efficient delivery operations",
        },
        {
          section: "3. Data Protection",
          text: "We protect your information through:\n\n• Secure servers\n• Data encryption in transit and at rest\n• Regular security audits\n\nWhile we implement strong security measures, no internet transmission is 100% secure.",
        },
        {
          section: "4. Information Sharing",
          text: "We may share your information with:\n\n• Payment processors\n• Insurance providers\n• Government authorities (when legally required)\n• Law enforcement agencies (as required by law)",
        },
        {
          section: "5. Your Rights and Choices",
          text: "As a delivery partner, you can:\n\n• Choose what information to provide\n• Opt out of promotional communications\n• Update your account information\n• Adjust app settings\n\nNote: Some information is required to use the platform's core features.",
        },
        {
          section: "6. Policy Updates",
          text: "Policy changes will be communicated through:\n\n• In-app notifications\n• Email notifications\n\nContinued use of the app after changes indicates acceptance of the updated policy.",
        },
        {
          section: "7. Contact Information",
          text: "For any questions or concerns about our privacy policy or how we handle your information, please contact us at privacy@afyasafe.com",
        },
      ],
    },
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-blue-800 mb-8 text-center">
          Privacy Policy
        </h1>

        {/* Tab Navigation */}
        <div className="flex flex-wrap gap-2 mb-8 justify-center">
          <button
            onClick={() => setActiveTab("doctor")}
            className={`px-4 py-2 rounded-full text-sm font-medium ${
              activeTab === "doctor"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Doctor App
          </button>
          <button
            onClick={() => setActiveTab("customer")}
            className={`px-4 py-2 rounded-full text-sm font-medium ${
              activeTab === "customer"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Customer App
          </button>
          <button
            onClick={() => setActiveTab("vendor")}
            className={`px-4 py-2 rounded-full text-sm font-medium ${
              activeTab === "vendor"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Vendor App
          </button>
          <button
            onClick={() => setActiveTab("partner")}
            className={`px-4 py-2 rounded-full text-sm font-medium ${
              activeTab === "partner"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Partner App
          </button>
        </div>

        {/* Content Section */}
        <div className="bg-white rounded-lg shadow-lg p-6 md:p-8">
          <div className="mb-6">
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              {policies[activeTab].title}
            </h2>
            <p className="text-sm text-gray-500">
              Last Updated: {policies[activeTab].lastUpdated}
            </p>
          </div>

          <div className="space-y-6">
            {policies[activeTab].content.map((item, index) => (
              <section key={index}>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                  {item.section}
                </h3>
                <p className="text-gray-600 leading-relaxed">{item.text}</p>
              </section>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
