import React, { useState } from "react";

const TermsAndConditions = () => {
  const [activeTab, setActiveTab] = useState("doctor");

  const terms = {
    doctor: {
      title: "AfyaSafe Doctor App Terms & Conditions",
      lastUpdated: "March 15, 2024",
      content: [
        {
          section: "1. Acceptance of Terms",
          text: "By using the AfyaSafe Doctor App ('the App'), you agree to be bound by these terms and conditions. If you do not agree to these terms, you should not use the app."
        },
        {
          section: "2. Use of the App",
          text: "The App is designed to help medical practices:\n\n• Manage their patients\n• Handle appointments\n• Process administrative tasks\n• Maintain medical records\n\nYou may use the app only for lawful purposes and in accordance with these terms and conditions."
        },
        {
          section: "3. User Accounts",
          text: "To use the app, you must:\n\n• Create a user account\n• Provide accurate and complete information\n• Keep your account information up to date\n• Maintain account security\n• Ensure medical credentials are valid"
        },
        {
          section: "4. User Content",
          text: "You are solely responsible for any content you upload, post, or make available through the app. You represent and warrant that:\n\n• You have all necessary rights to such content\n• Content does not violate any laws\n• Content does not infringe on third-party rights\n• Medical information is accurate and compliant"
        },
        {
          section: "5. Intellectual Property",
          text: "The AfyaSafe Doctor App and its contents are protected by:\n\n• Copyright\n• Trademark\n• Other intellectual property laws\n\nYou may not copy, modify, distribute, or create derivative works based on the app or its contents without our prior written consent."
        },
        {
          section: "6. Disclaimer of Warranties",
          text: "The App is provided 'as is' and 'as available' without warranty of any kind, express or implied. We do not warrant that:\n\n• The app will meet your requirements\n• Service will be uninterrupted or error-free\n• Errors will be corrected\n• The app will be compatible with your systems"
        },
        {
          section: "7. Limitation of Liability",
          text: "In no event shall we be liable for any:\n\n• Direct damages\n• Indirect damages\n• Incidental damages\n• Special damages\n• Consequential damages\n\narising out of or in connection with your use of the App, even if we have been advised of the possibility of such damages."
        },
        {
          section: "8. Indemnification",
          text: "You agree to indemnify and hold us harmless from any claims, damages, or expenses arising out of your use of the App, your violation of these terms and conditions, or your violation of any third-party rights."
        },
        {
          section: "9. Termination",
          text: "We may terminate your access to the App at any time for any reason without notice."
        },
        {
          section: "10. Governing Law",
          text: "These terms and conditions shall be governed by and construed in accordance with the laws of Kenya."
        },
        {
          section: "11. Changes to Terms",
          text: "We may update these terms and conditions from time to time. We will notify you of any significant changes by:\n\n• Posting a notice in the app\n• Sending an email notification"
        },
        {
          section: "12. Contact Information",
          text: "If you have any questions or concerns about these terms and conditions, please contact us at privacy@afyasafe.com"
        }
      ]
    },
    customer: {
      title: "AfyaSafe Customer App Terms & Conditions",
      lastUpdated: "March 15, 2024",
      content: [
        {
          section: "1. Acceptance of Terms",
          text: "By using the AfyaSafe Customer App, you agree to these Terms and Conditions. If you do not agree, please do not use the app.",
        },
        {
          section: "2. User Eligibility",
          text: "To use this app, you must:\n\n• Be at least 18 years old\n• Provide accurate personal information\n• Have the capacity to enter into legal agreements\n• Maintain accurate health information\n• Keep your account credentials secure",
        },
        {
          section: "3. Service Usage",
          text: "As a user, you agree to:\n\n• Book appointments responsibly\n• Provide accurate health information\n• Attend scheduled appointments\n• Pay for services as required\n• Follow healthcare providers' instructions",
        },
        {
          section: "4. Payment Terms",
          text: "Regarding payments:\n\n• Service fees are clearly displayed\n• Payment must be made before service delivery\n• Cancellation policies apply\n• Refunds are processed as per policy\n• Multiple payment options are available",
        },
        {
          section: "5. User Responsibilities",
          text: "You are responsible for:\n\n• Accurate personal information\n• Timely communication\n• Following medical advice\n• Respecting healthcare providers\n• Maintaining appointment schedules",
        },
      ],
    },
    vendor: {
      title: "AfyaSafe Vendor App Terms & Conditions",
      lastUpdated: "March 15, 2024",
      content: [
        {
          section: "1. Acceptance of Terms",
          text: "By using the AfyaSafe Vendor App, you agree to these Terms and Conditions. If you do not agree, please do not use the app.",
        },
        {
          section: "2. Vendor Requirements",
          text: "To use this app, you must:\n\n• Be a licensed pharmacy or healthcare facility\n• Maintain valid business permits\n• Comply with healthcare regulations\n• Provide accurate business information\n• Maintain quality standards",
        },
        {
          section: "3. Service Obligations",
          text: "As a vendor, you agree to:\n\n• Maintain accurate inventory\n• Process orders promptly\n• Ensure medicine quality\n• Follow proper storage guidelines\n• Maintain proper documentation",
        },
        {
          section: "4. Payment Processing",
          text: "Regarding payments:\n\n• Commission structures are clearly defined\n• Payment processing is secure\n• Settlement periods are specified\n• Refund policies must be followed\n• Financial records must be maintained",
        },
        {
          section: "5. Quality Control",
          text: "You must maintain:\n\n• Product quality standards\n• Proper storage conditions\n• Valid medicine licenses\n• Accurate product information\n• Professional service standards",
        },
      ],
    },
    partner: {
      title: "AfyaSafe Partner App Terms & Conditions",
      lastUpdated: "March 15, 2024",
      content: [
        {
          section: "1. Acceptance of Terms",
          text: "By using the AfyaSafe Partner App, you agree to these Terms and Conditions. If you do not agree, please do not use the app.",
        },
        {
          section: "2. Partner Requirements",
          text: "To become a delivery partner, you must:\n\n• Have a valid driver's license\n• Own or lease a suitable vehicle\n• Maintain vehicle insurance\n• Pass background checks\n• Complete required training",
        },
        {
          section: "3. Delivery Obligations",
          text: "As a delivery partner, you agree to:\n\n• Handle deliveries with care\n• Maintain delivery timelines\n• Follow safety protocols\n• Use provided delivery equipment\n• Maintain professional conduct",
        },
        {
          section: "4. Payment Terms",
          text: "Regarding payments:\n\n• Delivery fees are clearly defined\n• Payment processing is regular\n• Incentive structures are specified\n• Payment methods are secure\n• Commission rates are transparent",
        },
        {
          section: "5. Service Standards",
          text: "You must maintain:\n\n• Professional appearance\n• Vehicle cleanliness\n• Timely deliveries\n• Customer service standards\n• Safety protocols",
        },
      ],
    },
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-blue-800 mb-8 text-center">
          Terms & Conditions
        </h1>

        {/* Tab Navigation */}
        <div className="flex flex-wrap gap-2 mb-8 justify-center">
          <button
            onClick={() => setActiveTab("doctor")}
            className={`px-4 py-2 rounded-full text-sm font-medium ${
              activeTab === "doctor"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Doctor App
          </button>
          <button
            onClick={() => setActiveTab("customer")}
            className={`px-4 py-2 rounded-full text-sm font-medium ${
              activeTab === "customer"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Customer App
          </button>
          <button
            onClick={() => setActiveTab("vendor")}
            className={`px-4 py-2 rounded-full text-sm font-medium ${
              activeTab === "vendor"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Vendor App
          </button>
          <button
            onClick={() => setActiveTab("partner")}
            className={`px-4 py-2 rounded-full text-sm font-medium ${
              activeTab === "partner"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Partner App
          </button>
        </div>

        {/* Content Section */}
        <div className="bg-white rounded-lg shadow-lg p-6 md:p-8">
          <div className="mb-6">
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              {terms[activeTab].title}
            </h2>
            <p className="text-sm text-gray-500">
              Last Updated: {terms[activeTab].lastUpdated}
            </p>
          </div>

          <div className="space-y-6">
            {terms[activeTab].content.map((item, index) => (
              <section key={index}>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                  {item.section}
                </h3>
                <p className="text-gray-600 leading-relaxed whitespace-pre-line">
                  {item.text}
                </p>
              </section>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
