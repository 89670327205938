import React, { useState } from "react";
import { FaUser, FaEnvelope, FaPaperPlane } from "react-icons/fa";
import emailjs from "emailjs-com"; // Install using: npm install emailjs-com

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    try {
      const response = await emailjs.send(
        "service_2423elo", //Service ID
        "template_wppfatm", //Template ID
        {
          from_name: formData.name,
          from_email: formData.email,
          message: formData.message,
        },
        "g3PHAs7azhC4LmGuC" //User ID
      );
      console.log("Email sent successfully:", response);
      setStatus("Message sent successfully!");
      // Clear the form
      setFormData({ name: "", email: "", message: "" }); 
    } catch (error) {
      console.error("Error sending email:", error);
      setStatus("Failed to send message. Please try again.");
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center p-10 text-center"
      style={{ backgroundImage: 'url("blurred.jpg")' }}
    >
      <h1 className="text-5xl font-extrabold text-blue-800 mb-4">Contact Us</h1>
      <p className="text-lg max-w-md text-gray-700 mb-8">
        Have questions? Reach out to us, and we’ll get back to you shortly.
      </p>

      <form
        className="bg-white shadow-lg rounded-lg p-8 w-full max-w-lg space-y-6"
        onSubmit={handleSubmit}
      >
        {/* Name Input */}
        <div className="flex items-center border-b border-gray-300 py-2">
          <FaUser className="text-blue-500 mr-3" />
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 focus:outline-none text-gray-700 placeholder-gray-400"
            required
          />
        </div>

        {/* Email Input */}
        <div className="flex items-center border-b border-gray-300 py-2">
          <FaEnvelope className="text-blue-500 mr-3" />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 focus:outline-none text-gray-700 placeholder-gray-400"
            required
          />
        </div>

        {/* Message Textarea */}
        <div className="border-b border-gray-300 py-2">
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            className="w-full p-2 h-32 focus:outline-none text-gray-700 placeholder-gray-400 resize-none"
            required
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="flex items-center justify-center w-full px-4 py-3 bg-blue-600 text-white font-semibold rounded-full hover:bg-blue-700 transition duration-300"
        >
          <FaPaperPlane className="mr-2" />
          <span>Send Message</span>
        </button>
      </form>

      {/* Status Message */}
      {status && <p className="text-gray-700 mt-4">{status}</p>}
    </div>
  );
};

export default ContactUs;
